body {
    margin: 0px;
    //   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    //     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    //     sans-serif;
    font-family: Calibri, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: white;
}

html,
body,
#root {
    height: 100%;
    background-image: url("./images/background.gif");
    background-repeat: repeat;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}
