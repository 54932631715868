.about-page{
    .intro-section{
        background-color: #141414b3;
        color: white;
        padding: 12px;
        border-radius: 2px;
        text-align: left;
        font-size: 1.1em;
    }
    
    span.title{
        color: #4ABAE6;
        font-size: 2em;
        font-weight: bold;
    }
}

