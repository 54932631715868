.loading-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 100px 24px 24px 24px;
    width: 200px;

    h2, h3 {
        margin: 0;
    }


    .MuiCircularProgress-root{
        margin-top: 12px;
    }

    .MuiCircularProgress-colorPrimary{
        color: #3cb8e7;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .loading-wrapper{
        top: 345px;

        img{
            width: 130px;
        }
    }
}