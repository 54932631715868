.resource-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
    margin: 24px auto 0;

    // .refresh-button-wrapper{
    //     display: flex;
    //     justify-content: flex-end;
    //     width: 100%;

    //     button{
    //         background: none;
    //         border: none;
    //         color: white;
    //         cursor: pointer;
    //         margin-bottom: 6px;
    //     }
    // }

    &.audio {
        img {
            margin-bottom: 12px;
            border-radius: 10px;
        }
        video {
            height: 54px;
            max-width: 600px;
            width: 100%;
        }
    }

    video,
    img,
    audio,
    embed {
        max-width: 600px;
        width: 100%;
    }

    object{
        width: 100%;
        height: 500px;
    }

    .react-pdf {
        &__Document {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 12px;
        }

        &__Page {
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

            canvas {
                max-width: 100%;
                height: auto !important;
            }
        }

        &__message {
            padding: 20px;
            color: white;
        }
    }

    a{
        text-decoration: none;
        width: 100%
    }

    .download-button{
        width: 100%;
        background-color: #358600;
        color: white;
        margin-bottom: 12px;
        height: 42px;

        .MuiSvgIcon-root{
            padding-left: 3px;
        }
    }

    .download-button:hover{
        background-color: #2b6902;

    }
}

span.intro-text {
    padding: 12px 0;
}

.carousel-root {
    max-width: 600px;
    margin: auto;
    padding: 12px 0 0 0;

    .carousel {
        &.carousel-slider {
            .control-arrow {
                background: black;
                height: 55px;
                border-radius: 4px;
                opacity: 1;
                margin: auto 0;
                padding: 10px;
                border: 1px solid white;
            }

            .control-arrow:hover {
                background: black;
            }
        }

        .slide {
            background-color: transparent;
        }
    }
}

.carousel-loading-wrapper{
    max-width: 600px;
    margin: 24px auto;
    text-align: center;

    .MuiCircularProgress-colorPrimary{
        color: #3cb8e7;
    }
}

