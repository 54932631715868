.no-content-wrapper {
    text-align: center;
    padding: 24px;

    h2 {
        margin: 0;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .no-content-wrapper{
        // top: 350px;

        img{
            width: 130px;
        }
    }
}