.resource-badge.MuiCard-root {
    width: 150px;
    text-align: center;

    .MuiCardContent-root {
        display: flex;
        flex-direction: column;
        padding: 6px;

        video {
            width: 100%;
        }
    }

    .MuiCardMedia-root {
        height: 100px;
    }

    h1,
    h2,
    h3,
    a {
        color: rgba(0, 0, 0, 0.87);
    }

    .card-title-wrapper {
        min-height: 46px;
        padding-top: 6px;
        span {
            font-size: 1em;
            font-weight: bold;
        }
    }

    .resource-type-badge-wrapper {
        position: relative;
        top: -19px;
        margin: 0 0 0 auto;

        .resource-type-badge {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            margin-bottom: -37px;
            display: flex;
            color: white;
            align-items: center;
            justify-content: center;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

            .MuiSvgIcon-root {
                width: 15px;
            }
        }

        &.video {
            .resource-type-badge {
                background-color: #004fff;
                // color: black
            }
        }

        &.image {
            .resource-type-badge {
                background-color: #ff1654;
            }
        }

        &.audio {
            .resource-type-badge {
                background-color: #fc440f;
            }
        }
        &.pdf {
            .resource-type-badge {
                background-color: #358600;
            }
        }
        &.file {
            .resource-type-badge {
                background-color: #F18F01;
            }
        }
    }
}

.resource-badge.MuiCard-root:hover {
    position: relative;
    top: -1px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .resource-card.MuiCard-root {
        min-width: 296px;
        width: 100%;
        margin: 6px 0;
        text-align: center;
    }
}
