.full-height-content {
    min-height: calc(100vh - 262px); /* will cover the 100% of viewport minus footer */
    overflow: hidden;
    display: block;
    padding-bottom: 24px;

}

.content {
    padding: 140px 24px 24px 24px;
    max-width: 1080px;
    margin: 0 auto;
}

h1,
h2,
h3,
a {
    color: white;
}

.loading-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 100px 24px 24px 24px;
    width: 200px;

    h2 {
        margin: 0;
    }
}

.breadcrumb-wrapper{
    z-index: 1000;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .content {
        padding: 100px 12px 12px 12px;
        min-height: 170px;
    }

    .full-height-content {
        min-height: calc(100vh - 183px); /* will cover the 100% of viewport minus footer */
    }
}
