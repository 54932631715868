@keyframes FadeIn {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }

    85% {
        opacity: 1;
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.folder-card.MuiCard-root {
    display: flex;
    align-items: center;
    width: 40%;
    margin: 12px;
    text-align: center;
    box-shadow: 0 0 10px 4px #3cb8e7;
    opacity: 0; //must start invisible
    background-color: #3cb8e7;
    border: 1px solid white;
    animation: FadeIn 0.2s linear;
    animation-fill-mode: forwards;

    a {
        text-decoration: none;
        color: black;
    }

    span.folder-label {
        font-size: 1.5em;
        font-weight: bold;
        font-family: hobo-std, sans-serif;
    }

    .MuiCardContent-root {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: #3cb8e7;
        // border: 1px solid white;

        span {
            padding: 0 12px;
            color: #226896;
            font-weight: 400;
        }
    }
}

.folder-card.MuiCard-root:hover {
    position: relative;
    top: -1px;
    box-shadow: 0 0 8px 4px #3cb8e7;

    span.folder-label {
        color: #134c71;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .folder-card.MuiCard-root {
        width: 100%;
        margin: 6px 0;

        span.folder-label {
            font-size: 1em;
        }
    }

    .folder-card.MuiCard-root:hover {
        position: relative;
        top: 0px;
        box-shadow: 0 0 10px 4px #3cb8e7;

        span.folder-label {
            color: #226896;
        }
    }
}
