.home-page {
    .intro-section {
        text-align: center;

        .minion-gif-desktop {
            width: 100%;
            max-width: 650px;
        }

        .minion-gif-mobile {
            display: none;
        }

        .text-section {
            background-color: #141414b3;
            color: white;
            padding: 6px;
            border-radius: 2px;
            font-size: 1.3em;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .home-page {
        .intro-section {
            

            .minion-gif-desktop {
                display: none;
            }
            .minion-gif-mobile {
                display: block;
                width: 100%;
            }

            .text-section {
                text-align: center;
                padding: 2px;
                font-size: 1.2em;
            }
        }
    }
}
